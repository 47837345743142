import Api from "../../api";
import { apiStart, apiEnd, apiError } from "./api";
import {
  LOGIN,
  LOGGED_USER,
  API_FAILURE,
  REGISTER_CLIENT,
  LOGOUT,
  GET_BOULPIKS,
  REFRESH_CREDIT,
  EDIT_USER,
  VERIFY_PIN,
  VERIFY_PIN_FAILURE,
  RESET_PASSWORD,
  DISMISS_MODAL,
  UPDATE_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  GET_TRANSACTIONS,
  CAN_WIN
} from "./types";
import Cookie from "react-cookies";
import { getCurrentLanguage } from "../../constants/Utils";
import { navigate } from "./navigation";
import { onSuccess } from "./success";

function requestUserSuccess(type, user) {
  return { type, payload: { user } };
}

function requestTransactionSuccess(type, data) {
  return { type, payload: { data } };
}



function requestSuccess(type, data) {
  return { type, payload: { data } };
}

function requestFail(errorCode, language) {
  return { type: API_FAILURE, payload: { data: errorCode, language } };
}

export function getTransactions() {
  const language = getCurrentLanguage();
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    if (userId !== undefined) {
      try {
        const response = await Api.get(`user/${userId}/transactions`, {
          withCredentials: true  
        });
     
        if (response.data && response.data.success) {
          const transactions = response.data.result;

          dispatch(requestTransactionSuccess(GET_TRANSACTIONS, transactions));
          //window.location.href = 'withdrawal';
        } else {
          dispatch(requestFail(response.data.message, language));
        }
      } catch (error) {
        dispatch(apiError(error, language));
      } finally {
        dispatch(apiEnd());
      }
    } else {
      const noUser = {
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        balance: 0,
        bonus: 0,
        userType: {},
        profile: {},
      };
      dispatch(requestUserSuccess(LOGGED_USER, noUser));
    }
  };
}

export function rehydrate() {
  const language = getCurrentLanguage();
  return async (dispatch) => {
    const userId = await Cookie.load("userId");

    if (userId !== undefined) {
      try {
        const response = await Api.get(`/user/${userId}`);                                                                     

        if (response.data && response.data.success) {
          const user = response.data.result;
          dispatch(requestUserSuccess(LOGGED_USER, user));
          //window.location.href = 'withdrawal';
        } else {
          dispatch(requestFail(response.data.message, language));
        }
      } catch (error) {
        dispatch(apiError(error, language));
      } finally {
        dispatch(apiEnd());
      }
    } else {
      const noUser = {
        id: undefined,
        lastName: "",
        name: "",
        phone: "",
        pin: "",
        status: "",
        role: "",
        balance: 0,
        bonus: 0,
        userType: {},
        profile: {},
      };
      dispatch(requestUserSuccess(LOGGED_USER, noUser));
    }
  };
}

export function login(phone, password) {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());

    // Prepare URL-encoded payload
    const formUrlEncodedData = toFormUrlEncoded({ phone, password });

    try {
      const response = await Api.post("/login", formUrlEncodedData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",  // Ensure this matches the encoding type
        },
        withCredentials: true  
      });

      if (response.data && response.data.success) {
        const { user } = response.data.result;
        Cookie.save("userId", user.id);
        dispatch(requestUserSuccess(LOGIN, user));
      } else {
        dispatch(requestFail(response.data.message, language));
      }
    } catch (error) {
      dispatch(apiError(error, language));
    } finally {
      dispatch(apiEnd());
    }
  };
}

// Helper function to URL-encode the data
const toFormUrlEncoded = (obj) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");

export function logout() {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    Api.post(`/user/${userId}/logout`, null, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
    }).then(async (response) => {
      if (response.data.success) {
        await Cookie.remove("userId");
        dispatch(requestUserSuccess(LOGOUT, null));
      }
    });
  };
}

export function register(lastName, name, phone, password, pin) {
  console.log("HI");
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ lastName, name, phone, password, pin });
    Api.post("/user/register", formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          const { user } = response.data.result;
          Cookie.save();
          Api.defaults.headers.common["userId"] = user.id;
          dispatch(requestUserSuccess(REGISTER_CLIENT, user));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function registerSeller(
  name,
  phone,
  city,
  zone,
  address,
  password,
  pin
) {
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.post("/createVendeur", {
      nom: name,
      tel: phone,
      ville: city,
      zone,
      address,
      motDePasse: password,
      pin,
    })
      .then((response) => {
        if (response.data.success) {
          const { user, token } = response.data.data;
          const authToken = { user: user, token: token };
          Cookie.save("authToken", authToken, { path: "/", maxAge: 3600 });
          Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          Api.defaults.headers.common["userId"] = user._id;
          dispatch(requestUserSuccess(REGISTER_CLIENT, user, token));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function fetchUserInfo() {
  return async (dispatch) => {
    const language = getCurrentLanguage();
    const userId = await Cookie.load("userId");
    dispatch(apiStart());
    Api.get(`/user/${userId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(GET_BOULPIKS, response.data.result));
          dispatch(requestSuccess(REFRESH_CREDIT, response.data.result));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export async function updateUserInfo() {
  const userId = await Cookie.load("userId");
  return (dispatch) => {
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.put(`/user/${userId}`)
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(UPDATE_USER, response.data.result));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export  function rechargeMoncash(amount,phone, windowRef) {
 
  return async ( dispatch) => {
    const language = getCurrentLanguage();
    const userId = await Cookie.load("userId");
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone : phone , amount: amount * 1 });
  
    Api.post(`/user/${userId}/pay`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true  
    })
      .then((response) => {
        if (response.data.success) {

          dispatch(apiEnd());
          windowRef.location = response.data.result;
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export  function transferFundMoncash(amount,phone,onSuccess) {
 
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
   
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone:phone,amount: amount * 1, type: "Balance" });
  
    Api.post(`/user/${userId}/transfer`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          onSuccess("true");
          dispatch(apiEnd());
        } else {

          onSuccess(response.data.message);
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function updateCredit(amount, type, operation, via) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    const formData = toFormUrlEncoded({ type, amount: amount * 1, operation, via });

    dispatch(apiStart());

    Api.put(`/user/${userId}/credit`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,  // Ensure that cookies are sent with the request
    })
    .then((response) => {
      if (response.data.success) {
        dispatch(requestSuccess(response.data.result));
        
      } else {
        dispatch(requestFail(response.data.message, language));
      }
      dispatch(apiEnd());
    })
    .catch((error) => {
      dispatch(apiEnd());
      dispatch(apiError(error, language));
    });
  };
}



export function userCanWinNow() {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    Api.get(`/user${userId}/canWin`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }).then((response) => {
      if (response.data.success) {
          dispatch(requestSuccess(CAN_WIN, response.data.result));
          dispatch(apiEnd());
      } else {
        dispatch(apiEnd());
        dispatch(requestFail(response.data.message, language));
      }
    });
  };
}



export function refreshCredit(credit) {
  return (dispatch) => {
    dispatch(requestSuccess(REFRESH_CREDIT, credit));
  };
}


export function editUser(user, closeModal) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());
    Api.patch(`/user/${userId}`, {
      user,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(EDIT_USER, response.data.data));
          dispatch(apiEnd());
          closeModal();
          dispatch(onSuccess("Edit_Account", language));
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}



export function verifyUser(phone, isSuccess) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone: phone });
    Api.post(`/user/${userId}/verify`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })

      .then((response) => {
        if (response.data.success) {
          if (response.data.result) {
            dispatch(requestSuccess(VERIFY_USER_SUCCESS, phone));
          } else {
            dispatch(requestSuccess(VERIFY_USER_FAILURE, null));
          }
        } else {
          dispatch(requestSuccess(VERIFY_USER_FAILURE, null));
        }
        dispatch(apiEnd());
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}



export function verifyPin(phone, pin) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());
    const formData = toFormUrlEncoded({ phone: phone ,pin: pin});
    Api.post(`/user/${userId}/verifyPin`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          if(response.data.result){
            dispatch(requestSuccess(VERIFY_PIN, pin));
            dispatch(apiEnd());
          }else{
            dispatch(requestSuccess(VERIFY_PIN_FAILURE, null));
            dispatch(apiEnd());
          }
        
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export  function resetPassword(phone,pin, password) {
  return async (dispatch) => {
    const userId = await Cookie.load("userId");
    const language = getCurrentLanguage();
    dispatch(apiStart());

    const formData = toFormUrlEncoded({ phone: phone ,pin: pin,password:password});
    Api.put(`/user/${userId}/updatePassword`, formData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(requestSuccess(RESET_PASSWORD, null));
          dispatch(onSuccess("Reset_Password", language));
          dispatch(navigate("/"));
          dispatch(apiEnd());
        } else {
          dispatch(apiEnd());
          dispatch(requestFail(response.data.message, language));
        }
      })
      .catch((error) => {
        dispatch(apiError(error, language));
        dispatch(apiEnd());
      });
  };
}

export function dismissModals() {
  return (dispatch) => {
    dispatch(requestSuccess(DISMISS_MODAL, null));
  };
}
