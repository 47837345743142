import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
//import Menu from "../../components/Menu/Menu";
import UserProfile from "../../components/UserProfile/UserProfile";
import { getLabel } from "../../constants/Strings";
import styles from "./HomeScreen.module.css";
import { updateCredit } from "../../redux/actions/user";
//import { getUserNotification } from "../../redux/actions/notifications";
import Header from "../../components/Header/Header";
const DEFAULT_PRICE = 5;

class HomeScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLowBalanceModal: false,
      showModalConditionPlay: false,
      continuePlay: false,
    };
  }

  handlePlay = () => {
    const { profile } = this.props.user;

    if (this.state.continuePlay && profile) {
      this.handleCreditUpdateAndNavigation(DEFAULT_PRICE);
    } else {
      this.setState({ showLowBalanceModal: true });
    }
  };

  componentDidMount() {

    //const { dispatch } = this.props;
    //dispatch(fetchUserInfo());
    //dispatch(getUserNotification());
  }

  handleCreditUpdateAndNavigation = (DEFAULT_PRICE) => {
    const { profile } = this.props.user;

    if (profile.bonus >= DEFAULT_PRICE) {
      this.props.dispatch(updateCredit( DEFAULT_PRICE, "Bonus", "Reduce"));
    } else if (profile.balance >= DEFAULT_PRICE) {
      this.props.dispatch(updateCredit( DEFAULT_PRICE, "Balance", "Reduce"));
    } else {
      this.setState({ showLowBalanceModal: true });
      return;
    }
    
    this.props.history.push("/play-game");
  };

  handleCloseModal = () => this.setState({ showLowBalanceModal: false });
  handleDeclineModal = () => this.setState({ showModalConditionPlay: false });
  handleAcceptModal = () => {
    this.setState(
      { showModalConditionPlay: false, continuePlay: true },
      this.handlePlay
    );
  };

  render() {
    const { name, phone, profile } = this.props.user;
    const { balance, bonus } = profile || { balance: 0, bonus: 0 };
    if (profile?.bonus > 1000) {
      const amountAddedBalance = profile.bonus - 1000;
      this.props.dispatch(updateCredit( amountAddedBalance, "Bonus", "Reduce"));
      this.props.dispatch(updateCredit( amountAddedBalance, "Balance", "add"));
    }

    return (
      <div className={styles.container}>
        <div className={styles.menuIcon}>
          <Header />
        </div>
        <div className={styles.grayBackground}>
          <header className={styles.headerText}>
            <h1>
              {getLabel("Hello")} {name}
            </h1>
          </header>
          <UserProfile
            name={name}
            phone={phone}
            balance={balance}
            bonus={bonus}
          />
        </div>
        <div className={styles.gameTeaser}>
          <p>{getLabel("HomeText")}</p>
          <div className={styles.prize}>{getLabel("PrizeMoney")}</div>
        </div>
        <div className={styles.saveButtonPosition}>
          <Button
            className={styles.saveButton}
            style={{ backgroundColor: "#F99500", color: "white" }}
            onClick={() => this.setState({ showModalConditionPlay: true })}
          >
            {getLabel("Play")}
          </Button>
        </div>

        <Modal
          open={this.state.showLowBalanceModal}
          onClose={this.handleCloseModal}
        >
          <Modal.Header style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("LowBalance")}
          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("LowBalanceText")}
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "red", color: "white" }}>
            <Button onClick={this.handleCloseModal}>{getLabel("Close")}</Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showModalConditionPlay}
          onClose={this.handleDeclineModal}
        >
          <Modal.Content style={{ backgroundColor: "red", color: "white" }}>
            {getLabel("RulesFeeText")} {DEFAULT_PRICE} Gdes
          </Modal.Content>
          <Modal.Actions style={{ backgroundColor: "red", color: "white" }}>
            <div
              style={{
                justifyContent: "center",
                flex: 1,
                display: "flex",
                padding: "5%",
              }}
            >
              <Button
                onClick={this.handleDeclineModal}
                style={{ marginRight: "10px" }}
              >
                {getLabel("Decline")}
              </Button>
              <Button onClick={this.handleAcceptModal}>
                {getLabel("Accept")}
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ user , notification}) {
  //console.log("Notifications : ", notification);
  return { user , notification};
}

export default withRouter(connect(mapStateToProps)(HomeScreen));
